import { componentSelector } from './toggable-tracker-selectors';
import { ToggableTracker } from './ToggableTracker';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    document.querySelectorAll(componentSelector).forEach(($toggableTracker) => new ToggableTracker($toggableTracker));
  },
  false
);
