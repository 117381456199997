/**
 * @typedef {Object} Tracker
 * @property {Boolean} enabled
 * @property {Number} id
 * @property {String} incrementPath
 */

/**
 * @typedef {Object} ToggableTrackerConfiguration
 * @property {Object} site
 * @property {String} site.title
 * @property {Number} site.id
 * @property {Object} toggable
 * @property {Tracker} toggable.tracker
 * @property {Tracker} toggable.trackerHiddenContent
 * @property {Object} googleAnalytics
 * @property {String[]} googleAnalytics.accounts
 */

import { btnShowHiddenContentSelector, hiddenContentSelector } from './toggable-tracker-selectors';

/**
 * @param {HTMLElement} $tracker
 * @return {ToggableTrackerConfiguration}
 */
const getConfig = ($tracker) => JSON.parse($tracker.dataset.configuration);

export class ToggableTracker {
  constructor($tracker) {
    this.$tracker = $tracker;
    this.$btnShowHiddenContent = this.$tracker.querySelector(btnShowHiddenContentSelector);
    this.$hiddenContent = this.$tracker.querySelector(hiddenContentSelector);
    this.config = getConfig(this.$tracker);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Toggable Tracker', {
        $tracker: this.$tracker,
        $btnShowHiddenContent: this.$btnShowHiddenContent,
        $hiddenContent: this.$hiddenContent,
        config: this.config,
      });
    }

    this.init();
  }

  init() {
    this.$btnShowHiddenContent.addEventListener(
      'click',
      () => {
        this.$btnShowHiddenContent.classList.add('d-none');
        this.$hiddenContent.classList.remove('d-none');

        this.incrementTracker(this.config.toggable.tracker);
        this.sendGoogleAnalyticsEvent('hidden_content_revealed');
        this.sendMatomoEvent('hidden_content_revealed');
      },
      false
    );

    this.$hiddenContent.addEventListener(
      'click',
      () => {
        if (this.config.toggable.trackerHiddenContent.enabled) {
          this.incrementTracker(this.config.toggable.trackerHiddenContent);
          this.sendGoogleAnalyticsEvent('hidden_content_clicked');
          this.sendMatomoEvent('hidden_content_clicked');
        }
      },
      false
    );
  }

  /**
   * @param {Tracker} tracker
   */
  // eslint-disable-next-line class-methods-use-this
  incrementTracker(tracker) {
    // I won't import Axios just for doing this...
    const xhr = new XMLHttpRequest();
    xhr.open('POST', tracker.incrementPath, true);
    xhr.send(null);
  }

  // eslint-disable-next-line class-methods-use-this
  sendGoogleAnalyticsEvent(eventName) {
    if (global.isGoogleAnalyticsEnabled()) {
      global.gtag('event', eventName, {
        site_title: this.config.site.title,
        site_id: this.config.site.id,
        tracker_id: this.config.toggable.tracker.id,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  sendMatomoEvent(eventName) {
    if (global.isMatomoEnabled()) {
      // eslint-disable-next-line no-underscore-dangle
      global._paq.push([
        'trackEvent',
        'Y360ToggableTracker',
        eventName,
        this.config.toggable.tracker.id,
        this.config.site.id,
      ]);
    }
  }
}
